import { memo } from 'react';

import { ReactComponent as SpinnerIcon } from 'assets/icons/Spinner.svg';
import { useStyles } from 'components/loader/styles';

const Loader = () => {
  const { root, spinner } = useStyles();
  return (
    <div className={root}>
      <SpinnerIcon className={spinner} />
    </div>
  );
};

export default memo(Loader);
