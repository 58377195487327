import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    background: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
  },
  spinner: {
    width: 40,
    height: 40,
    color: theme.colors.grey[70],
    fill: theme.palette.primary,
    animation: '$spin 1s linear infinite',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));
