export const DASHBOARD = '/';
export const ACCOUNTS = '/accounts';
export const ACCESS_CONTROL = '/access';
export const CHANGES = '/changes';
export const DISPLAY = '/display';
export const FINANCIAL = '/financial';
export const LICENSES = '/licenses';
export const MONITORING = '/monitoring';
export const PLATFORM = '/platform';
export const ROLES = '/roles';
export const SECURITY = '/security';
export const SUPPORT = '/support';
export const TRAININGS = '/trainings';
export const SETTINGS = '/settings';

export const SIGN_IN_PATH = `${process.env.REACT_APP_AUTH_URI}/signin`;
