import { memo } from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';

import Typography from 'components/typography';
import { ReactComponent as ChevronRight } from 'assets/icons/Chevron Right.svg';

import { useStyles } from './styles';

const MenuItem = ({ Icon, label, href, subMenus, className, ...rest }) => {
  const { menuItem, menuIcon, subMenuWrapper, subMenuList, subMenuItem, arrowIcon, menuText } = useStyles();
  const location = useLocation();
  const hasActiveChild = () => {
    return subMenus?.some(sub => location.pathname === sub.href);
  };

  if (!subMenus) {
    return (
      <NavLink to={href} className={({ isActive }) => clsx(className, menuItem, { current: isActive })} {...rest}>
        <Icon className={menuIcon} />
        <Typography variant="bodyM" className={menuText}>
          {label}
        </Typography>
      </NavLink>
    );
  }
  return (
    <div className={clsx(className, menuItem, { current: hasActiveChild() })} {...rest}>
      <div className={menuIcon}>
        <Icon />
      </div>
      <Typography variant="bodyM" className={menuText}>
        {label}
      </Typography>
      <ChevronRight className={arrowIcon} />
      <div className={subMenuWrapper}>
        <div className={subMenuList}>
          {subMenus.map(sub => (
            <NavLink className={subMenuItem} to={sub.href} key={sub.label}>
              <div>{sub.label}</div>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(MenuItem);
