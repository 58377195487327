export const INDIVIDUAL = 'individual';

export const PHONE_NUMBER_PREFIX = '+1';

export const NUMBER_REGEXP = /[0-9]/;
export const UPPERCASE_REGEXP = /[A-Z]/;
export const CHARACTER_LENGTH_REGEXP = /.{8,}/;
export const SYMBOLS_REGEXP = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;

export const strengthRegexp = {
  uppercase: UPPERCASE_REGEXP,
  number: NUMBER_REGEXP,
  symbol: SYMBOLS_REGEXP,
  length: CHARACTER_LENGTH_REGEXP,
};
