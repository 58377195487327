import { lazy } from 'react';

import {
  ACCESS_CONTROL,
  ACCOUNTS,
  CHANGES,
  DASHBOARD,
  DISPLAY,
  FINANCIAL,
  LICENSES,
  MONITORING,
  PLATFORM,
  ROLES,
  SECURITY,
  SUPPORT,
  TRAININGS,
} from 'constants/path';
import { withSuspense } from 'utils';

export const routes = [
  {
    path: DASHBOARD,
    Component: withSuspense(lazy(() => import('pages/main'))),
  },
  {
    path: ACCOUNTS,
    Component: withSuspense(lazy(() => import('pages/accounts'))),
  },
  {
    path: PLATFORM,
    Component: withSuspense(lazy(() => import('pages/platform'))),
  },
  {
    path: FINANCIAL,
    Component: withSuspense(lazy(() => import('pages/financial'))),
  },
  {
    path: LICENSES,
    Component: withSuspense(lazy(() => import('pages/licenses'))),
  },
  {
    path: ROLES,
    Component: withSuspense(lazy(() => import('pages/roles'))),
  },
  {
    path: ACCESS_CONTROL,
    Component: withSuspense(lazy(() => import('pages/accessControl'))),
  },
  {
    path: SECURITY,
    Component: withSuspense(lazy(() => import('pages/security'))),
  },
  {
    path: CHANGES,
    Component: withSuspense(lazy(() => import('pages/changes'))),
  },
  {
    path: MONITORING,
    Component: withSuspense(lazy(() => import('pages/monitoring'))),
  },
  {
    path: DISPLAY,
    Component: withSuspense(lazy(() => import('pages/display'))),
  },
  {
    path: SUPPORT,
    Component: withSuspense(lazy(() => import('pages/support'))),
  },
  {
    path: TRAININGS,
    Component: withSuspense(lazy(() => import('pages/trainings'))),
  },
];
