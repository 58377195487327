import { ReactComponent as PlatformIcon } from 'assets/icons/twoTone/Platform.svg';
import { ReactComponent as DashboardIcon } from 'assets/icons/twoTone/Dashboard.svg';
import { ReactComponent as AccountsIcon } from 'assets/icons/twoTone/Accounts.svg';
import { ReactComponent as FinancialIcon } from 'assets/icons//twoTone/Financial.svg';
import { ReactComponent as LicensesIcon } from 'assets/icons/twoTone/Licenses.svg';
import { ReactComponent as RolesIcon } from 'assets/icons/twoTone/Roles.svg';
import { ReactComponent as AccessControlIcon } from 'assets/icons/twoTone/AccessControl.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/twoTone/Security.svg';
import { ReactComponent as ChangesIcon } from 'assets/icons/twoTone/Changes.svg';
import { ReactComponent as MonitoringIcon } from 'assets/icons/twoTone/Monitoring.svg';
import { ReactComponent as DisplayIcon } from 'assets/icons/twoTone/Display.svg';
import { ReactComponent as SupportIcon } from 'assets/icons/twoTone/SmartConnect.svg';
import { ReactComponent as TrainingsIcon } from 'assets/icons/twoTone/Trainings.svg';
import {
  ACCESS_CONTROL,
  ACCOUNTS,
  CHANGES,
  DASHBOARD,
  DISPLAY,
  FINANCIAL,
  LICENSES,
  MONITORING,
  PLATFORM,
  ROLES,
  SECURITY,
  SUPPORT,
  TRAININGS,
} from 'constants/path';

export const navigation = [
  [
    {
      label: 'Dashboard',
      href: DASHBOARD,
      Icon: DashboardIcon,
    },
    {
      label: 'Accounts',
      href: ACCOUNTS,
      Icon: AccountsIcon,
    },
    {
      label: 'Platform',
      href: PLATFORM,
      Icon: PlatformIcon,
    },
    {
      label: 'Financial',
      href: FINANCIAL,
      Icon: FinancialIcon,
    },
    {
      label: 'Licenses',
      href: LICENSES,
      Icon: LicensesIcon,
    },
  ],
  [
    {
      label: 'Roles',
      href: ROLES,
      Icon: RolesIcon,
    },
    {
      label: 'Access Control',
      href: ACCESS_CONTROL,
      Icon: AccessControlIcon,
    },
    {
      label: 'Security',
      href: SECURITY,
      Icon: SecurityIcon,
    },
    {
      label: 'Changes',
      href: CHANGES,
      Icon: ChangesIcon,
    },
    {
      label: 'Monitoring',
      href: MONITORING,
      Icon: MonitoringIcon,
    },
  ],
  [
    {
      label: 'Display',
      href: DISPLAY,
      Icon: DisplayIcon,
    },
  ],
  [
    {
      label: 'Support',
      href: SUPPORT,
      Icon: SupportIcon,
    },
    {
      label: 'Trainings',
      href: TRAININGS,
      Icon: TrainingsIcon,
    },
  ],
];
