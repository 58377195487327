import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';

import { store } from 'store';
import { TranslatorProvider } from 'utils/translator';
import { ThemeWrapper } from 'config/theme';
import { opsClient } from 'connection/graphql/appolloClients';

import './index.css';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={opsClient}>
    <Provider store={store}>
      <LoadScript loadingElement={<div>ll</div>} googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <TranslatorProvider>
          <ThemeWrapper>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeWrapper>
        </TranslatorProvider>
      </LoadScript>
    </Provider>
  </ApolloProvider>,
);
