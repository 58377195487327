import uuid from './uuid';
import dayjs from './dayjs';
import isEmpty from './isEmpty';
import isEqual from './isEqual';
import withSuspense from './withSuspense';
import { setCookie, getCookie, eraseCookie } from './cookie';
import { isMobile, isIOS, isAndroid, isWindowsPhone } from './isMobile';
import { getFromStorage, removeFromStorage, saveToStorage } from './persist';
import { shortenEmail, maskPhoneNumber, getPasswordStrength, formatPhoneNumber, capitalize } from './string';
import { hexToRgbA } from './styles';

export {
  uuid,
  dayjs,
  isIOS,
  isEqual,
  isEmpty,
  isMobile,
  setCookie,
  getCookie,
  isAndroid,
  eraseCookie,
  withSuspense,
  saveToStorage,
  getFromStorage,
  isWindowsPhone,
  removeFromStorage,
  shortenEmail,
  maskPhoneNumber,
  getPasswordStrength,
  formatPhoneNumber,
  hexToRgbA,
  capitalize,
};
